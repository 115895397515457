export const environment = {
    production: false,
    auth: {
        clientID: '95pzTqGyHzWbEoy4nIcUsOFzDYw3Tkpg',
        audience: 'https://pricefx.growthfuel.app',
        domain: 'ra-gf.us.auth0.com',
        algorithm: 'RSA256',
        responseType: 'token id_token',
        scope: 'openid profile email'
    },
    api: 'https://api.test.growthfuel.app',
    env: 'test'
};
